// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-article-article-page-js": () => import("./../../../src/components/Article/ArticlePage.js" /* webpackChunkName: "component---src-components-article-article-page-js" */),
  "component---src-components-original-content-original-content-page-js": () => import("./../../../src/components/OriginalContent/OriginalContentPage.js" /* webpackChunkName: "component---src-components-original-content-original-content-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-film-fund-js": () => import("./../../../src/pages/film-fund.js" /* webpackChunkName: "component---src-pages-film-fund-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquire-js": () => import("./../../../src/pages/inquire.js" /* webpackChunkName: "component---src-pages-inquire-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-opportunities-js": () => import("./../../../src/pages/opportunities.js" /* webpackChunkName: "component---src-pages-opportunities-js" */),
  "component---src-pages-original-content-js": () => import("./../../../src/pages/original-content.js" /* webpackChunkName: "component---src-pages-original-content-js" */),
  "component---src-pages-platform-beta-eoi-js": () => import("./../../../src/pages/platform-beta-eoi.js" /* webpackChunkName: "component---src-pages-platform-beta-eoi-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-talent-form-js": () => import("./../../../src/pages/talent-form.js" /* webpackChunkName: "component---src-pages-talent-form-js" */),
  "component---src-pages-the-team-js": () => import("./../../../src/pages/the-team.js" /* webpackChunkName: "component---src-pages-the-team-js" */)
}

